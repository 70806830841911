
'use strict'
const _ = require('lodash')
const santaTypesDefinitions = require('../../../definitions/santaTypesDefinitions')
const inlineContentMixin = require('../../../mixins/inlineContentMixin')
const scrollMixin = require('../../../mixins/scrollMixin/scrollMixin')
const skinsJson = require('../skins/skins.json')
const skinBasedComp = require('../../../mixins/skinBasedComp')
const createReactClass = require('create-react-class')

const skins = _.pick(skinsJson, ['wysiwyg.viewer.skins.FormContainerSkin'])

module.exports = createReactClass({
    displayName: 'FormContainer',
    mixins: [skinBasedComp(skins), scrollMixin, inlineContentMixin],
    statics: {
        compType: 'wysiwyg.viewer.components.FormContainer'
    },
    propTypes: {
        isResponsive: santaTypesDefinitions.RendererModel.isResponsive,
        layoutContainerClassName: santaTypesDefinitions.Layout.layoutContainerClassName
    },
    getSkinProperties() {
        const preventSubmit = e => e.preventDefault()
        return {
            '': {
                'data-is-responsive': this.props.isResponsive,
                style: {}
            },
            form: {
                onSubmit: preventSubmit,
                className: this.props.layoutContainerClassName,
                children: this.getChildrenRenderer({contentSkinPartId: 'form'})
            }
        }
    }
})
