'use strict'

const _ = require('lodash')
const React = require('react')
const createReactElement = require('../utils/createReactElement')
const createReactClass = require('create-react-class')
const santaTypesDefinitions = require('../definitions/santaTypesDefinitions')

const overflowWrapper = createReactClass({
    displayName: 'overflowWrapper',
    render() {
        const {elementRef, ...rest} = this.props
        return createReactElement('div', {ref: elementRef, ...rest})
    }
})

const wrapperFactory = React.createElement.bind(null, overflowWrapper)

const isHorizontalScrollOverflow = element => {
    const boundingRect = element.getBoundingClientRect()
    const horizontalOverflow = element.scrollWidth - boundingRect.width
    const verticalOverflow = element.scrollHeight - boundingRect.height

    return horizontalOverflow > verticalOverflow
}

const getScrollPortion = (element, isHorizontalLayout) => {
    const boundingRect = element.getBoundingClientRect()
    return isHorizontalLayout ? boundingRect.width : boundingRect.height
}

const getNewScrollPosition = (element, scrollDirection, isHorizontalLayout) => {
    const currentScrollPosition = isHorizontalLayout ? element.scrollLeft : element.scrollTop
    const totalScrollSpace = isHorizontalLayout ? element.scrollWidth : element.scrollHeight
    const scrollPortion = getScrollPortion(element, isHorizontalLayout)

    const scrollDirectionFactor = scrollDirection === 'forward' ? 1 : -1
    let newScrollPosition = currentScrollPosition + scrollDirectionFactor * scrollPortion
    if (newScrollPosition + scrollPortion > totalScrollSpace) { // return to the begining on forward
        newScrollPosition = 0
    } else if (newScrollPosition < 0) { // return to the end on backward scroll
        newScrollPosition = totalScrollSpace
    }

    return newScrollPosition
}

module.exports = {
    propTypes: {
        id: santaTypesDefinitions.Component.id,
        isResponsive: santaTypesDefinitions.RendererModel.isResponsive,
        registerToInnerScroll: santaTypesDefinitions.Scrollable.registerToInnerScroll,
        unregisterInnerScroll: santaTypesDefinitions.Scrollable.unregisterInnerScroll,
        layoutContainerClassName: santaTypesDefinitions.Layout.layoutContainerClassName,
        layoutContainerOverflowWrapperClassName: santaTypesDefinitions.Layout.layoutContainerOverflowWrapperClassName,
        shouldRenderOverflowWrapper: santaTypesDefinitions.Layout.shouldRenderOverflowWrapper
    },

    componentWillMount() {
        this.overflowWrapperRef = React.createRef()
    },

    componentDidMount() {
        if (this.props.shouldRenderOverflowWrapper) {
            this.props.registerToInnerScroll(this.props.id, '[id$=overflowWrapper]')
        }
    },

    componentDidUpdate(prevProps) {
        if (this.props.shouldRenderOverflowWrapper !== prevProps.shouldRenderOverflowWrapper) {
            if (this.props.shouldRenderOverflowWrapper) {
                this.props.registerToInnerScroll(this.props.id, '[id$=overflowWrapper]')
            } else {
                this.props.unregisterInnerScroll(this.props.id)
            }
        }
    },

    componentWillUnmount() {
        if (this.props.shouldRenderOverflowWrapper) {
            this.props.unregisterInnerScroll(this.props.id)
        }
    },

    scrollInnerContent(scrollDirection) {
        const overflowWrapperElement = this.overflowWrapperRef.current
        if (!overflowWrapperElement) {
            return
        }

        const isHorizontalLayout = isHorizontalScrollOverflow(overflowWrapperElement)
        const newScrollPosition = getNewScrollPosition(overflowWrapperElement, scrollDirection, isHorizontalLayout)

        overflowWrapperElement.scrollTo({
            [isHorizontalLayout ? 'left' : 'top']: newScrollPosition,
            behavior: 'smooth'
        })
    },

    getInlineContent(props) {
        return _.assign({}, props,
            {
                className: this.props.layoutContainerClassName
            },
            this.props.shouldRenderOverflowWrapper && {
                wrap: [wrapperFactory, {
                    id: `${this.props.id}overflowWrapper`,
                    elementRef: this.overflowWrapperRef,
                    className: this.props.layoutContainerOverflowWrapperClassName
                }]
            }
        )
    }
}
