/* Autogenerated file. Do not modify */
'use strict'
const skins = {}
 skins['AutoWidthAnchorSkin'] = {
  "react": [],
  "css": {
    "%": "pointer-events:none !important;"
  }
}
 skins['wysiwyg.common.components.anchor.viewer.skins.AnchorSkin'] = {
  "react": [],
  "css": {
    "%": "visibility:hidden;pointer-events:none;width:0 !important;"
  }
}

module.exports = skins