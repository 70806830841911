'use strict'

const containerFactory = require('./containers/containerFactory')
const FormContainer = require('./containers/FormContainer')
const santaTypesDefinitions = require('../../definitions/santaTypesDefinitions')
const _ = require('lodash')
const createReactClass = require('create-react-class')

const createContainer = (...args) => createReactClass(containerFactory(...args))

const LegacyContainer = createContainer({
    compType: 'wixapps.integration.components.Area',
    defaultSkinName: 'wysiwyg.viewer.skins.area.DefaultAreaSkin',
    legacyInlineContent: true,
    includeScrollMixin: true
})

const GroupContainer = createContainer({
    displayName: 'WixGroupContainer',
    compType: 'wysiwyg.viewer.components.Group',
    defaultSkinName: 'wysiwyg.viewer.components.GroupSkin',
    includeScrollMixin: false,
    pointerEventsNone: true
})

const Container = createContainer({
    compType: 'mobile.core.components.Container',
    defaultSkinName: 'wysiwyg.viewer.skins.area.DefaultAreaSkin',
    includeScrollMixin: true
})

const AppWidget = createContainer({
    displayName: 'AppWidget',
    compType: 'platform.components.AppWidget',
    defaultSkinName: 'platform.components.skins.AppWidgetSkin',
    includeScrollMixin: true
})

const compTypeToTagNameMap = {
    'responsive.components.HeaderSection': 'header',
    'responsive.components.FooterSection': 'footer',
    'responsive.components.Section': 'section'
}


const addTagNameByChildType = containerFactoryOptions => _.assign(
    containerFactoryOptions, {
        extraPropTypes: {
            refCompChildType: santaTypesDefinitions.RefComponent.refCompChildType
        },
        getExtraRootProps: ({refCompChildType}) => {
            const tagName = compTypeToTagNameMap[refCompChildType]
            return tagName && {tagName}
        }
    }
)

const RefComponent = createContainer(addTagNameByChildType({
    displayName: 'RefComponent',
    compType: 'wysiwyg.viewer.components.RefComponent',
    defaultSkinName: 'skins.core.InlineSkin',
    includeScrollMixin: true,
    childrenRendererParams: {
        overrides: {fitToContentHeight: true}
    }
}))

const afterScrollPropTypes = {
    isPlayingAllowed: santaTypesDefinitions.RenderFlags.isPlayingAllowed.isRequired,
    isAfterScroll: santaTypesDefinitions.HeaderContainer.isAfterScroll.isRequired
}

const getScrollDataState = ({isAfterScroll, isPlayingAllowed}) => ({
    ['data-state']: [isAfterScroll && 'scrolled', isPlayingAllowed && 'transition-allowed'].filter(Boolean).join(' ')
})

const tagNamePropTypes = {
    isParentRefComponent: santaTypesDefinitions.Component.isParentRefComponent
}
const getTagNameProp = (configTagName, {isParentRefComponent}) => configTagName && !isParentRefComponent && {tagName: configTagName}

const addTagNameIfParentNotRef = containerFactoryOptions => _.assign(
    containerFactoryOptions, {
        extraPropTypes: tagNamePropTypes,
        getExtraRootProps: props => getTagNameProp(containerFactoryOptions.tagName, props)
    }
)

//** Moved to Santa/mediaContainers
// const sectionCompType = 'responsive.components.Section'

// const Section = createContainer(addTagNameIfParentNotRef({
//     displayName: 'Section',
//     compType: sectionCompType,
//     defaultSkinName: 'skins.core.InlineSkin',
//     tagName: compTypeToTagNameMap[sectionCompType],
//     hasBackground: true
// }))

const headerSectionCompType = 'responsive.components.HeaderSection'

const HeaderSection = createContainer({
    extraPropTypes: _.assign({}, tagNamePropTypes, afterScrollPropTypes),
    getExtraRootProps: props => _.merge(getScrollDataState(props), getTagNameProp(compTypeToTagNameMap[headerSectionCompType], props)),
    displayName: 'HeaderSection',
    compType: headerSectionCompType,
    defaultSkinName: 'skins.core.InlineSkin',
    tagName: compTypeToTagNameMap[headerSectionCompType]
})

const footerSectionCompType = 'responsive.components.FooterSection'

const FooterSection = createContainer(addTagNameIfParentNotRef({
    displayName: 'FooterSection',
    compType: footerSectionCompType,
    defaultSkinName: 'skins.core.InlineSkin',
    tagName: compTypeToTagNameMap[footerSectionCompType]
}))

const MembersAreaSection = createContainer({
    displayName: 'MembersAreaSection',
    compType: 'responsive.components.MembersAreaSection',
    defaultSkinName: 'skins.core.InlineSkin'
})

module.exports = {
    FormContainer,
    GroupContainer,
    LegacyContainer,
    Container,
    RefComponent,
    AppWidget,
    HeaderSection,
    FooterSection,
    MembersAreaSection
}
