'use strict'
const siteConstants = require('./siteConstants')
const {ACTION_TYPES} = siteConstants

/**
 * Map from event type to ist registration function name.
 * universal-editor-app use this map in order to build component's behaviors,
 * This map should be synced with siteConstants.ACTION_TYPES
 */
module.exports = {
    [ACTION_TYPES.CLICK]: 'onClick',
    [ACTION_TYPES.DBL_CLICK]: 'onDblClick',
    [ACTION_TYPES.MOUSE_IN]: 'onMouseIn',
    [ACTION_TYPES.MOUSE_OUT]: 'onMouseOut',
    [ACTION_TYPES.CHANGE]: 'onChange',
    [ACTION_TYPES.ON_INPUT]: 'onInput',
    [ACTION_TYPES.BLUR]: 'onBlur',
    [ACTION_TYPES.FOCUS]: 'onFocus',
    [ACTION_TYPES.IMAGE_CHANGED]: 'onCurrentItemChanged',
    [ACTION_TYPES.IMAGE_EXPANDED]: undefined,
    [ACTION_TYPES.ITEM_CLICKED]: 'onItemClicked',
    [ACTION_TYPES.CELL_SELECT]: 'onCellSelect',
    [ACTION_TYPES.CELL_EDIT]: undefined,
    [ACTION_TYPES.ROW_SELECT]: 'onRowSelect',
    [ACTION_TYPES.FETCH_DATA]: undefined,
    [ACTION_TYPES.DATA_CHANGE]: 'onDataChange',
    [ACTION_TYPES.ON_TIMEOUT]: 'onTimeout',
    [ACTION_TYPES.ON_VERIFY]: 'onVerify',
    [ACTION_TYPES.ON_ERROR]: 'onError',
    [ACTION_TYPES.ON_PLAY]: 'onPlay',
    [ACTION_TYPES.ON_PAUSE]: 'onPause',
    [ACTION_TYPES.ON_PROGRESS]: 'onProgress',
    [ACTION_TYPES.ON_ENDED]: 'onEnded',
    [ACTION_TYPES.AUTOPLAY_OFF]: 'onPause',
    [ACTION_TYPES.AUTOPLAY_ON]: 'onPlay',
    [ACTION_TYPES.PLAY_ENDED]: undefined,
    [ACTION_TYPES.PLAY_PROGRESS]: undefined,
    [ACTION_TYPES.KEY_PRESS]: 'onKeyPress',
    [ACTION_TYPES.SCREEN_IN]: undefined,
    [ACTION_TYPES.VIEWPORT_ENTER]: 'onViewportEnter',
    [ACTION_TYPES.VIEWPORT_LEAVE]: 'onViewportLeave',
    [ACTION_TYPES.SCROLL]: undefined,
    [ACTION_TYPES.VALIDATE]: undefined,
    [ACTION_TYPES.SET_CUSTOM_VALIDITY]: undefined,
    [ACTION_TYPES.SYNC_VALIDATION_DATA]: undefined,
    [ACTION_TYPES.UPDATE_VALIDITY_INDICATION]: undefined,
    [ACTION_TYPES.MESSAGE]: 'onMessage',
    [ACTION_TYPES.UPLOAD_COMPLETE]: undefined,
    [ACTION_TYPES.ITEM_READY]: 'onItemReady',
    [ACTION_TYPES.ITEM_REMOVED]: 'onItemRemoved',
    [ACTION_TYPES.TAG_CLICK]: undefined,
    [ACTION_TYPES.QUICK_ACTION_BAR_ITEM_CLICKED]: 'onItemClicked',
    [ACTION_TYPES.GOOGLE_MAP_MARKER_CLICKED]: 'onMarkerClicked',
    [ACTION_TYPES.GOOGLE_MAP_CLICKED]: 'onMapClicked',
    [ACTION_TYPES.ICON_MOUSE_IN]: undefined,
    [ACTION_TYPES.ON_STATE_CHANGE]: 'onStateChange'
}
