'use strict'

const rgbaRegexp = /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/
const hexRegexp = /^#([0-9a-f]{1,2})([0-9a-f]{1,2})([0-9a-f]{1,2})$/i

const channelByIndex = ['r', 'g', 'b']

function extractRgbFromHex(color) {
    const isShortHex = !(color.length % 2)
    const parts = color.match(hexRegexp)
    
    return (parts || []).splice(1, 3).reduce((acc, hex, index) => {
        const channel = channelByIndex[index]
        acc[channel] = parseInt(isShortHex ? hex + hex : hex, 16)
        
        return acc
    }, {})
}

function extractRgbFromRgbaString(color) {
    const parts = color.match(rgbaRegexp)
    
    return (parts || []).splice(1, 3).reduce((acc, dec, index) => {
        const channel = channelByIndex[index]
        acc[channel] = dec
        
        return acc
    }, {})
}

module.exports = (color, opacity) => {
    if (!color) {
        return color
    }
    
    let rgb
    
    if (color[0] === '#') {
        rgb = extractRgbFromHex(color)
    } else {
        rgb = extractRgbFromRgbaString(color)
    }
    
    if (
        typeof rgb.r === 'undefined' ||
        typeof rgb.g === 'undefined' ||
        typeof rgb.b === 'undefined'
    ) {
        return color
    }
    return `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${opacity})`
}
