'use strict'

const PropTypes = require('prop-types')
const styleNodeUtils = require('../../utils/styleNodeUtils')
const santaTypesDefinitions = require('../../definitions/santaTypesDefinitions')
const coreUtilsLib = require('@wix/santa-core-utils')

const fontCss = coreUtilsLib.fonts

const WixFontsStyleNode = props => {
    const {colorsMap, fontsMap, textThemes, styleRoot} = props
    let css = null

    if (colorsMap && textThemes) { //using of textThemes is priority
        css = fontCss.getTextThemesCss(textThemes, colorsMap)
    } else if (colorsMap && fontsMap) {
        css = fontCss.getThemeFontsCss(fontsMap, colorsMap)
    }

    return css && styleNodeUtils.generateStyleNode('theme_fonts', css, styleRoot)
}

WixFontsStyleNode.displayName = 'WixFontsStyleNode'
WixFontsStyleNode.propTypes = {
    fontsMap: santaTypesDefinitions.Fonts.fontsMap,
    colorsMap: santaTypesDefinitions.Theme.colorsMap,
    textThemes: santaTypesDefinitions.Theme.textThemes,
    styleRoot: PropTypes.string
}

module.exports = WixFontsStyleNode


