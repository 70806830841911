'use strict'
const _ = require('lodash')

const DS_SERVER_BASE = 'https://apps.wix.com'

const dsServerUrl = (siteHeaderUrl, {useAbsolutePath} = {
    useAbsolutePath: false
}) => `${useAbsolutePath ? DS_SERVER_BASE : ''}/document-services-server/v2/query/apps/${siteHeaderUrl}`

const fetchDS = (siteHeaderUrl, resource, options) => fetch(`${dsServerUrl(siteHeaderUrl, options)}${resource}`, {
    credentials: 'include'
}).then(res => res.json())

const getWidgetId = ({rootCompId}) => rootCompId[0] === '#' ? rootCompId.substr(1) : rootCompId

const getWidgetStructure = (siteHeaderUrl, widgetId, options = {}) => fetchDS(siteHeaderUrl, `/widget/${widgetId}`, options)

const getWidgetStructureByAppData = (appData, widgetPageId, variationId, options = {}) => {
    const {appDefinitionId, widgets} = appData
    const siteHeaderUrl = _.get(appData, 'appFields.platform.baseUrls.siteHeaderUrl') || _.get(appData, 'appFields.platform.studio.siteHeaderUrl')
    const devCenterWidgetId = _.findKey(widgets, widgetData => _.get(widgetData, 'componentFields.appStudioFields.id') === widgetPageId)
    const createVariationIdParam = () => `&variationId=${variationId}`

    const url = `/app_widget/${widgetPageId}?appDefinitionId=${appDefinitionId}&devCenterWidgetId=${devCenterWidgetId}${variationId ? createVariationIdParam() : ''}`

    return fetchDS(siteHeaderUrl, url, options)
        .then(({APP_WIDGET}) => APP_WIDGET)
}

/**
 * @description Fetches the appDescriptor from DS Server
 * @returns An object with widgets property, keys are widgetId, value is widget descriptor
 */
const getAppDescriptor = (siteHeaderUrl, options = {}) =>
    fetchDS(siteHeaderUrl, '/app', options)
        .then(app => {
            const {APP_DESCRIPTOR} = app
            const appDescriptor = _.isArray(APP_DESCRIPTOR) ? {widgets: APP_DESCRIPTOR} : APP_DESCRIPTOR
            return _.defaults({
                widgets: _.keyBy(appDescriptor.widgets, getWidgetId),
                customDefinitions: _.assign({}, ..._.get(appDescriptor, 'customDefinitions', []))
            }, appDescriptor)
        })

module.exports = {
    getWidgetStructure,
    getAppDescriptor,
    getWidgetStructureByAppData
}
