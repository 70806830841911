'use strict'
const _ = require('lodash')

const REQUEST_TYPES = {
    BRIGHTNESS: 'brightness'
}
const REQUEST_STATUS = {
    INIT: 'init',
    PENDING: 'pending',
    COMPLETE: 'complete'
}

/***** Helper Classes *****/
function RequestsCache() {
    _.forEach(REQUEST_TYPES, type => {
        this[type] = {}
    })
}

RequestsCache.prototype = {
    getReqResult(requestType, imageUrl) {
        const req = this[requestType][imageUrl]
        if (req && req.status === REQUEST_STATUS.COMPLETE) {
            return req.result
        }
        return null
    },
    setReqResult(requestType, imageUrl, result) {
        this[requestType][imageUrl] = this[requestType][imageUrl] || {}
        this[requestType][imageUrl].result = result
    },
    getReqStatus(requestType, imageUrl) {
        const req = this[requestType][imageUrl]
        return req && req.status || null
    },
    setReqStatus(requestType, imageUrl, status) {
        this[requestType][imageUrl] = this[requestType][imageUrl] || {}
        this[requestType][imageUrl].status = status
    },
    remove(requestType, imageUrl) {
        delete this[requestType][imageUrl]
    },
    registerCallbacksForResult(requestType, imageUrl, onSuccess, onError) {
        this[requestType][imageUrl] = this[requestType][imageUrl] || {}
        const req = this[requestType][imageUrl]
        req.callbacks = req.callbacks || {onSuccess: [], onError: []}
        req.callbacks.onSuccess.push(onSuccess)
        req.callbacks.onError.push(onError)
    },
    clearResultCallbacks(requestType, imageUrl) {
        delete this[requestType][imageUrl].callbacks
    },
    getResultSuccessCallbacks(requestType, imageUrl) {
        const req = this[requestType][imageUrl]
        return req && req.callbacks && req.callbacks.onSuccess || []
    },
    getResultErrorCallbacks(requestType, imageUrl) {
        const req = this[requestType][imageUrl]
        return req && req.callbacks && req.callbacks.onError || []
    }
}

/***** Global Variables *****/
const requestsCache = new RequestsCache() // eslint-disable-line santa/no-module-state

/***** Util Methods *****/
function computeImageBrightness(imageData) {
    let meanBrightness = 0
    const PIXELS = imageData.length / 4

    for (let p = 0; p < imageData.length; p += 4) {
        const normalizedR = imageData[p] / 255
        const normalizedG = imageData[p + 1] / 255
        const normalizedB = imageData[p + 2] / 255

        const maxNormalizedComponent = Math.max(normalizedR, normalizedG, normalizedB)
        const brightness = maxNormalizedComponent * 100

        meanBrightness += brightness / PIXELS
    }

    return meanBrightness
}

function workerOnMessage(e) {
    const imageData = e.data
    const imageBrightnessResult = computeImageBrightness(imageData)
    postMessage(JSON.stringify(imageBrightnessResult))
}

function getWorkerCodeAsString() {
    const computeImageBrightnessDef = String(computeImageBrightness)
    const onMessage = `onmessage = ${String(workerOnMessage)};`

    return computeImageBrightnessDef + onMessage
}

function loadImageOntoTempNode(imageUrl, onSuccess, onError) {
    const tempImageNode = new window.Image()
    tempImageNode.crossOrigin = 'Anonymous'
    tempImageNode.onload = function () {
        onSuccess(tempImageNode)
    }
    tempImageNode.onerror = onError
    tempImageNode.src = imageUrl
}

function getImageDataFromCanvas(imageNode, imageDimensions) {
    const canvas = window.document.createElement('canvas')
    const context = canvas.getContext('2d')
    context.drawImage(imageNode, 0, 0)

    return context.getImageData(0, 0, imageDimensions.width, imageDimensions.height).data
}

function runAnalyzerWorker(imageData, onSuccess, onError) {
    if (!window.Worker) {
        onError('Browser does not support Web Workers')
    }

    const blob = new window.Blob([getWorkerCodeAsString()])
    const blobUrl = window.URL.createObjectURL(blob)
    const worker = new window.Worker(blobUrl)

    worker.onmessage = function (e) {
        worker.terminate()
        const data = JSON.parse(e.data)
        if (_.isFinite(data)) {
            onSuccess(data)
        } else {
            onError()
        }
    }
    worker.onerror = onError
    worker.postMessage(imageData)
}

function analyzeImage(imageUrl, imageDimensions, onSuccess, onError) {
    loadImageOntoTempNode(imageUrl, imageNode => {
        requestsCache.setReqStatus(REQUEST_TYPES.BRIGHTNESS, imageUrl, REQUEST_STATUS.PENDING)
        const imageData = getImageDataFromCanvas(imageNode, imageDimensions)
        runAnalyzerWorker(imageData, onSuccess, onError)
    }, onError)
}

function isCanvasSupported() {
    const elem = window.document.createElement('canvas')
    return !!(elem.getContext && elem.getContext('2d'))
}

function analysisError(imageUrl) {
    const errorCallbacks = requestsCache.getResultErrorCallbacks(REQUEST_TYPES.BRIGHTNESS, imageUrl)
    requestsCache.remove(REQUEST_TYPES.BRIGHTNESS, imageUrl)

    _.forEach(errorCallbacks, callback => {
        callback()
    })
}

function analysisSuccess(imageUrl, result) {
    const successCallbacks = requestsCache.getResultSuccessCallbacks(REQUEST_TYPES.BRIGHTNESS, imageUrl)
    requestsCache.setReqStatus(REQUEST_TYPES.BRIGHTNESS, imageUrl, REQUEST_STATUS.COMPLETE)
    requestsCache.setReqResult(REQUEST_TYPES.BRIGHTNESS, imageUrl, result)

    _.forEach(successCallbacks, callback => {
        callback(result)
    })

    requestsCache.clearResultCallbacks(REQUEST_TYPES.BRIGHTNESS, imageUrl)
}

function getImageMeanBrightness(imageUrl, imageDimensions, onSuccess, onError) {
    onError = onError || _.noop
    onSuccess = onSuccess || _.noop
    if (!isCanvasSupported()) {
        onError('HTML5 <canvas> is not supported in this browser')
    }

    const reqStatus = requestsCache.getReqStatus(REQUEST_TYPES.BRIGHTNESS, imageUrl)
    switch (reqStatus) {
        case REQUEST_STATUS.COMPLETE:
            const resultFromCache = requestsCache.getReqResult(REQUEST_TYPES.BRIGHTNESS, imageUrl)
            onSuccess(resultFromCache)
            break
        case null:
            requestsCache.setReqStatus(REQUEST_TYPES.BRIGHTNESS, imageUrl, REQUEST_STATUS.INIT)
            requestsCache.registerCallbacksForResult(REQUEST_TYPES.BRIGHTNESS, imageUrl, onSuccess, onError)
            analyzeImage(imageUrl, imageDimensions, _.partial(analysisSuccess, imageUrl), _.partial(analysisError, imageUrl))
            break
        default:
            requestsCache.registerCallbacksForResult(REQUEST_TYPES.BRIGHTNESS, imageUrl, onSuccess, onError)
            break
    }
}

module.exports = {
    getImageMeanBrightness
}