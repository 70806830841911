'use strict'

const _ = require('lodash')
const imageAnalyzer = require('./imageAnalyzer')

function getDevicePixelRatio(currentUrl, devicePixelRatio) {
    //we should be able to force devicePixelRatio from url by using the query param -
    const query = _.get(currentUrl, 'query', {})
    const devicePixelRatioQueryParam = _(query).keys().find(key => _.includes(['devicepixelratio'], key.toLowerCase()))
    const devicePixelRatioValueForceFromUrl = Number(query[devicePixelRatioQueryParam])
    return devicePixelRatioValueForceFromUrl || devicePixelRatio || 1
}

/**
 * Get image full path and css from imageServices
 * @param {object} imageInfo
 * @param {object} imageInfo.imageData
 * @param {number} imageInfo.containerWidth
 * @param {number} imageInfo.containerHeight
 * @param {string} [imageInfo.fittingType]
 * @param {string} [imageInfo.alignType]
 * @param {string} [imageInfo.quality]
 * @param {string} [imageInfo.displayMode]
 * @param {function} getMediaFullStaticUrl
 * @param {object} currentUrl
 * @param {number} devicePixelRatio
 * @param {string} [htmlTag='img']
 * @returns {Object}
 */
function getImageComputedProperties(imageClientApi, imageInfo, getMediaFullStaticUrl, currentUrl, devicePixelRatio, htmlTag) {
    //todo: CLNT-5323 , wixapp sildergallery proxy is generating image data without uri
    if (!imageInfo.containerWidth || !imageInfo.containerHeight || !imageInfo.imageData.uri) {
        return {uri: '', css: {}}
    }
    const imageData = imageInfo.imageData
    const fittingType = imageInfo.displayMode || imageClientApi.fittingTypes.SCALE_TO_FILL
    const imageOptions = _.assign(
        _.pick(imageData, ['upscaleMethod']),
        _.pick(imageInfo, 'filters'),
        imageInfo.quality || imageData.quality
    )

    devicePixelRatio = getDevicePixelRatio(currentUrl, devicePixelRatio)

    const src = _.assign(_.pick(imageData, ['width', 'height', 'crop', 'name', 'focalPoint']), {id: imageData.uri})
    const target = {
        width: imageInfo.containerWidth,
        height: imageInfo.containerHeight,
        htmlTag: htmlTag || 'img',
        pixelAspectRatio: devicePixelRatio,
        alignment: imageInfo.alignType || imageClientApi.alignTypes.CENTER
    }

    const imageComputedProperties = imageClientApi.getData(fittingType, src, target, imageOptions)
    imageComputedProperties.uri = getMediaFullStaticUrl(imageComputedProperties.uri)
    if (imageData.itemProp || imageInfo.addItemProp) {
        imageComputedProperties.itemProp = imageData.itemProp || 'image'
    }

    if (_.has(imageInfo, 'labelledById')) {
        imageComputedProperties['aria-labelledby'] = imageInfo.labelledById
    }

    if (_.has(imageInfo, 'describedById')) {
        imageComputedProperties['aria-describedby'] = imageInfo.describedById
    }

    return imageComputedProperties
}

/**
 * Get the size to be set for a containing component (when it's mutable), after taking the image's display mode into account
 *
 * @deprecated Please use imageClientApi if possible
 *
 * @param imageClientApi
 * @param containerOriginalSize {{width: number, height: number}}
 * @param {{width: number, height: number}} originalImageSize  the original image size (e.g. image data)
 * @param displayMode
 * @returns {{width: number, height: number}} the containing element correct size
 */
function getContainerSize(imageClientApi, containerOriginalSize, originalImageSize, displayMode) {
    const imageProportion = originalImageSize.width / originalImageSize.height
    let containerSize
    let exactWidth
    let exactHeight
    const isFitWidth = displayMode === imageClientApi.fittingTypes.LEGACY_FIT_WIDTH
    const isFitHeight = displayMode === imageClientApi.fittingTypes.LEGACY_FIT_HEIGHT

    if (isFitWidth) {
        exactHeight = containerOriginalSize.width / imageProportion
        containerSize = getRoundedSize(containerOriginalSize.width, exactHeight)
    } else if (isFitHeight) {
        exactWidth = containerOriginalSize.height * imageProportion
        containerSize = getRoundedSize(exactWidth, containerOriginalSize.height)
    } else {
        containerSize = _.clone(containerOriginalSize)
    }

    return containerSize
}

/**
 * @private
 * @param exactWidth
 * @param exactHeight
 * @returns {{width: number, exactWidth: *, height: number, exactHeight: *}}
 */
function getRoundedSize(exactWidth, exactHeight) {
    return {
        width: Math.ceil(exactWidth),
        exactWidth,
        height: Math.ceil(exactHeight),
        exactHeight
    }
}

/**
 * @class imageCommon.imageUtils
 */
module.exports = {
    getContainerSize,
    getImageComputedProperties,
    getImageMeanBrightness: imageAnalyzer.getImageMeanBrightness
}

